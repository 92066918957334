import { Input } from 'antd';
import React, { useCallback, useState, useEffect, useRef, createRef } from 'react';
import { T, DataTip } from '../../../../components/Translations';
import i18n from '../../../../Shared/Translation/i18n';
import { AppCode, SubAppCode /*, AppPages*/ } from '../../../../project/Defines';
import { ApplicationFormField, FieldGroup } from '../../../../Shared/Form/formFields';
import { DropDownValueList } from '../../../../Shared/DropDown';
import CustomTooltip from '../../../../Shared/CustomTooltip';
import { formatMinutes } from '../../../../project/utilities';
import { /*useHistory, useLocation,*/ useParams /*, useRouteMatch*/} from "react-router";

export default function Zone1(props) {
    const { /*history ,*/ zone, allowMessages } = props;
    const params = useParams();
    const { id } = params;
    const refElement = useRef(null);
    const { subApplicationsList, popupProps, application, subapplication, applicationChange, data, setData, defaultData, calculatedApiData, calculate, isOpen, setIsOpen, allData, expertModeChanged, sectionHeaderStyle } = props;
    const autoWidth = subapplication ? "auto" : "";
    const onError = () => {
        props.onError();
    }
    
    return (<>
        <view filter="" transparent="">
            <wrap>
                <div className="section_group">
                    <number style={sectionHeaderStyle}>1</number>
                    <div className={"sections " + autoWidth}>
                        <div className="form_fields">
                            <div className="input_cont title">
                                <text className="" style={sectionHeaderStyle}><b>
                                    <T>text.batiment</T>
                                </b></text>
                            </div>
                        </div>
                        <div className="form_fields">
                            <div className="input_cont title">
                                <DropDownValueList valuelist={subApplicationsList.map((item) => { return { value: item.id, text: item.label } })}
                                    value={subapplication && subApplicationsList.filter(item => item.code.toLowerCase() === subapplication).length > 0 ? subApplicationsList.filter(item => item.code.toLowerCase() === subapplication)[0].id : ""}
                                    onChange={applicationChange}
                                    disabled={subApplicationsList && subApplicationsList.length === 1}
                                    useTranslation={true}
                                />
                            </div>
                        </div>
                        {subapplication && <>
                            {
                                subApplicationsList && <AppFields allowMessages={allowMessages} subapplication={subapplication} data={data} setData={setData} allData={allData} defaultData={defaultData} onError={onError} //NOSONAR
                                />
                            }
                            {allData && data &&
                                <ApplicationFormField id={id} allData={allData} startLabel="text.temperature_minimale_deau_froide" endLabel="text.celsius_degree" type="select" valuelist={allData.TEFS.enum} value={data.TEFS} name="TEFS" setData={setData} formFieldClass="small " />
                            }
                            <toolbar transparent="" compact="">
                                {subapplication !== AppCode.PROCESSUSINDUSTRIEL &&
                                    <action>
                                        <button ref={refElement} onClick={() => { setIsOpen(!isOpen) }} className="button primary"><text><T>text.FO_BUTTON_EXPERT</T></text></button>
                                        {isOpen && <CustomTooltip fixedHeaderSize={60} Component={ExpertModeContent} params={params} refElement={refElement} tooltipKey={`tooltip2${id}`} headerText={subapplication === SubAppCode.LOGEMENTSPRIVES || subapplication === SubAppCode.LOGEMENTSSOCIAUX ? "text.consommation_journaliere_maximale" : null} componentProps={{ ...popupProps, subapplication }} hasHeader={true} />}
                                    </action>
                                }
                                {!calculatedApiData && 1 > 2 && < action right="">
                                    <button
                                        onClick={() => { calculate(data) }}
                                        // className="button primary micro"><icon>calc</icon>
                                        className="button primary"><T>text.calculs</T>
                                    </button>
                                </action>}
                            </toolbar>
                        </>
                        }
                    </div>
                    {(!calculatedApiData ||+ zone === 1) && <div v_center="" className={"sections s_medium "}>
                        <toolbar transparent="" compact="">
                            <wrap >
                                <action center="">
                                    <button
                                        onClick={() => {
                                            calculate(data);
                                          
                                        }}
                                        // className="button primary micro"><icon>calc</icon>
                                        className="button primary larger"><icon>calculator</icon><text><T>text.calculs</T></text>
                                    </button>
                                </action>
                            </wrap>
                        </toolbar>
                    </div>}
                    {subapplication && calculatedApiData && +zone >= 2 && <>
                        <CalculatedFields application={application} calculatedApiData={calculatedApiData} expertModeChanged={expertModeChanged} sectionHeaderStyle={sectionHeaderStyle} />
                    </>}
                </div>
            </wrap>
        </view>
    </>)
}
const getFieldsCount = (subapplication) => {
    switch (subapplication) {
        case SubAppCode.LOGEMENTSPRIVES:
        case SubAppCode.LOGEMENTSSOCIAUX:
            return { appFieldsCount: 6, expertModeFieldsCount: 6 };
        case SubAppCode.RESIDENCESSENIORS:
            return { appFieldsCount: 1, expertModeFieldsCount: 1 };
        case SubAppCode.RESIDENCESETUDIANTE:
        case SubAppCode.FOYERDEJEUNESTRAVAILLEURS:
            return { appFieldsCount: 3, expertModeFieldsCount: 6 };
        case SubAppCode.CAMPING:
            return { appFieldsCount: 2, expertModeFieldsCount: 4 };
        case SubAppCode.AFFAIRES:
        case SubAppCode.MONTAGNE:
        case SubAppCode.TOURISME:
            return { appFieldsCount: 3, expertModeFieldsCount: 6 };
        case SubAppCode.STADEFOOTBALL:
        case SubAppCode.STADERUGBY:
            return { appFieldsCount: 3, expertModeFieldsCount: 6 };
        case SubAppCode.CASERNE:
        case SubAppCode.VESTIAIREDENTREPRISE:
            return { appFieldsCount: 3, expertModeFieldsCount: 1 };
        case SubAppCode.STATIONDELAVAGE:
            return { appFieldsCount: 4, expertModeFieldsCount: 4 };
        case SubAppCode.INTERNAT:
            return { appFieldsCount: 2, expertModeFieldsCount: 1 };
        case SubAppCode.GYMNASE:
            return { appFieldsCount: 3, expertModeFieldsCount: 6 };
        case SubAppCode.PISCINE:
        case SubAppCode.SALLEDEFITNESS:
            return { appFieldsCount: 2, expertModeFieldsCount: 3 };
        case SubAppCode.HOPITALCLINIQUEDOUCHES:
        case SubAppCode.EHPAD:
            return { appFieldsCount: 2, expertModeFieldsCount: 2 };
        case SubAppCode.TRADITIONNEL:
        case SubAppCode.GASTRONOMIQUE:
        case SubAppCode.RAPIDE:
        case SubAppCode.COLLECTIF:
            return { appFieldsCount: 2, expertModeFieldsCount: 6 };
        case SubAppCode.PROCESSUSINDUSTRIEL:
            return { appFieldsCount: 5, expertModeFieldsCount: 6 };

        // no default
    }
}

const AppFields = (props) => {
    const [refs, setRefs] = useState({});
    const { data, setData, subapplication, allData, onError, allowMessages } = props;
    const { id } = useParams();


    useEffect(() => {
        setRefs((prev) => Array(getFieldsCount(subapplication).appFieldsCount)
            .fill()
            .map((_, i) => refs[i] || createRef()),
        );
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const onKeyDown = (e, index) => { //NOSONAR
        let code = e.event.code;
        if (code === 'ArrowDown' || code === 'ArrowUp' || code === 'ArrowRight' || code === 'ArrowLeft' || code === 'Enter' || code === 'NumpadEnter') {
            e.event.preventDefault();
            e.event.stopPropagation();
        }
        if (code === 'ArrowDown' && refs[index + 1]) {
            refs[index + 1].current.props.prefixCls ? refs[index + 1].current.focus() : refs[index + 1].current.instance.focus();
        } else if (code === 'ArrowUp' && refs[index - 1]) {
            refs[index - 1].current.props.prefixCls ? refs[index - 1].current.focus() : refs[index - 1].current.instance.focus();
        } else if (code === 'ArrowRight' && refs[index + 3]) {
            refs[index + 3].current.props.prefixCls ? refs[index + 3].current.focus() : refs[index + 3].current.instance.focus();
        } else if (code === 'ArrowLeft' && refs[index - 3]) {
            refs[index - 3].current.props.prefixCls ? refs[index - 3].current.focus() : refs[index - 3].current.instance.focus();
        } else if (code === 'Enter' || code === 'NumpadEnter') {
            refs[index].current.props.prefixCls ? refs[index].current.focus() : refs[index].current.instance.blur();
        } 
    }

    if (data) {
        switch (subapplication) {
            case SubAppCode.LOGEMENTSPRIVES:
            case SubAppCode.LOGEMENTSSOCIAUX:
                return <>
                    <FieldGroup className="justify">
                        <ApplicationFormField id={id} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }} allData={allData} onError={onError} tabIndex={1} endLabel="text.t1" type="number" value={data.nbT1} min={allData.nbT1.minimum} max={allData.nbT1.maximum} name="nbT1" setData={setData} formFieldClass="middle" endLabelProps={{ className: "", data_length: "xxx-medium", wrap: "" }} //NOSONAR
                        />
                        <ApplicationFormField id={id} ref={refs[3]} onKeyDown={(e) => { onKeyDown(e, 3, true) }} allData={allData} onError={onError} tabIndex={4} endLabel="text.t4" type="number" value={data.nbT4} min={allData.nbT4.minimum} max={allData.nbT4.maximum} name="nbT4" setData={setData} formFieldClass="middle" endLabelProps={{ className: "", data_length: "xxx-medium", wrap: "" }} //NOSONAR
                        />
                    </FieldGroup>
                    <FieldGroup className="justify">
                        <ApplicationFormField id={id} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1, true) }} allData={allData} onError={onError} tabIndex={2} endLabel="text.t2" type="number" value={data.nbT2} min={allData.nbT2.minimum} max={allData.nbT2.maximum} name="nbT2" setData={setData} formFieldClass="middle" endLabelProps={{ className: "", data_length: "xxx-medium", wrap: "" }} //NOSONAR
                        />
                        <ApplicationFormField id={id} ref={refs[4]} onKeyDown={(e) => { onKeyDown(e, 4, true) }} allData={allData} onError={onError} tabIndex={5} endLabel="text.t5" type="number" value={data.nbT5} min={allData.nbT5.minimum} max={allData.nbT5.maximum} name="nbT5" setData={setData} formFieldClass="middle" endLabelProps={{ className: "", data_length: "xxx-medium", wrap: "" }} //NOSONAR
                        />
                    </FieldGroup>
                    <FieldGroup className="justify">
                        <ApplicationFormField id={id} ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2, true) }} allData={allData} onError={onError} tabIndex={3} endLabel="text.t3" type="number" value={data.nbT3} min={allData.nbT3.minimum} max={allData.nbT3.maximum} name="nbT3" setData={setData} formFieldClass="middle" endLabelProps={{ className: "", data_length: "xxx-medium", wrap: "" }} //NOSONAR
                        />
                        <ApplicationFormField id={id} ref={refs[5]} onKeyDown={(e) => { onKeyDown(e, 5, true) }} allData={allData} onError={onError} tabIndex={6} endLabel="text.t6" type="number" value={data.nbT6} min={allData.nbT6.minimum} max={allData.nbT6.maximum} name="nbT6" setData={setData} formFieldClass="middle" endLabelProps={{ className: "", data_length: "xxx-medium", wrap: "" }} //NOSONAR
                        />
                    </FieldGroup>
                    { +data.nbtTotal > 0 ? <FieldGroup className="justify">
                        <ApplicationFormField id={id} allData={allData} endLabel="text.nombre_total_de_logements" type="number" value={(data.nbtTotal && +data.nbtTotal) || null} formFieldClass="read_only small center" disabled={true} bold={true} />
                    </FieldGroup> :
                        <ApplicationFormField id={id} allData={allData} type="number" formFieldClass="read_only middle center" disabled={true} />
                    }
                </>;

            case SubAppCode.RESIDENCESSENIORS:
                return <>
                    <ApplicationFormField id={id} allData={allData} onError={onError} startLabel="text.nombre_de_logements" type="number" value={data.nbT1} min={allData.nbT1.minimum} max={allData.nbT1.maximum} name="nbT1" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_SENIOR_NBL" />
                </>
            case SubAppCode.RESIDENCESETUDIANTE:
                return <>
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} endLabel="text.nombre_de_lits" type="number" value={data.NbLit} min={allData.NbLit.minimum} max={allData.NbLit.maximum} name="NbLit" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_STUDENT_NBL" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} endLabel="text.nombre_de_couverts_midi" type="number" value={data.NbCouvM} min={allData.NbCouvM.minimum} max={allData.NbCouvM.maximum} name="NbCouvM" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2) }} onError={onError} endLabel="text.nombre_de_couverts_soir" type="number" value={data.NbCouvS} min={allData.NbCouvS.minimum} max={allData.NbCouvS.maximum} name="NbCouvS" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                </>
            case SubAppCode.FOYERDEJEUNESTRAVAILLEURS:
                return <>
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} endLabel="text.nombre_de_lits" type="number" value={data.NbLit} min={allData.NbLit.minimum} max={allData.NbLit.maximum} name="NbLit" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_YOUNGWORKER_NBL" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} endLabel="text.nombre_de_couverts_midi" type="number" value={data.NbCouvM} min={allData.NbCouvM.minimum} max={allData.NbCouvM.maximum} name="NbCouvM" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2) }} onError={onError} endLabel="text.nombre_de_couverts_soir" type="number" value={data.NbCouvS} min={allData.NbCouvS.minimum} max={allData.NbCouvS.maximum} name="NbCouvS" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                </>
            case SubAppCode.CAMPING:
                return <>
                    <ApplicationFormField id={id} allData={allData} /*ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }}*/ startLabel="text.nombre_detoiles_du_camping" type="select" valuelist={allData.NbE.enum} value={data.NbE} name="NbE" setData={setData} formFieldClass="middle" />
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.nombre_de_douches_du_bloc_sanitaire" type="number" value={data.NbD} min={allData.NbD.minimum} max={allData.NbD.maximum} name="NbD" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_CAMPING_NBD" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.nombre_demplacements_desservis_par_le_bloc_sanitaire" type="number" value={data.NbEm} min={allData.NbEm.minimum} max={allData.NbEm.maximum} name="NbEm" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_CAMPING_NB_EMP" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} /*ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2, true) }}*/ startLabel="text.bord_de_mer" type="select" valuelist={[{ text: "FO_ETU_NO", value: 1 }, { text: "FO_ETU_YES", value: 2 }]} value={data.bdm} name="bdm" setData={setData} formFieldClass="middle" />
                </>
            case SubAppCode.AFFAIRES:
            case SubAppCode.MONTAGNE:
            case SubAppCode.TOURISME:
                return <>
                    <ApplicationFormField id={id} allData={allData} /*ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }}*/ startLabel="text.nombre_detoiles_de_lhotel" type="select" valuelist={allData.NbE.enum} value={data.NbE} name="NbE" setData={setData} formFieldClass="middle" />
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.nombre_de_chambres" type="number" value={data.NbC} min={allData.NbC.minimum} max={allData.NbC.maximum} name="NbC" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.nombre_de_couverts_midi" type="number" value={data.NbCouvM} min={allData.NbCouvM.minimum} max={allData.NbCouvM.maximum} name="NbCouvM" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2) }} onError={onError} startLabel="text.nombre_de_couverts_soir" type="number" value={data.NbCouvS} min={allData.NbCouvS.minimum} max={allData.NbCouvS.maximum} name="NbCouvS" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                </>
            case SubAppCode.STADEFOOTBALL:
            case SubAppCode.STADERUGBY:
                return <>
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.nombre_de_postes_de_douches" type="number" value={data.NbD} min={allData.NbD.minimum} max={allData.NbD.maximum} /*name="NbC"*/ name="NbD" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField allowMessages={allowMessages} id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.nombre_de_personnes_par_match" type="number" value={data.PersActiv} min={allData.PersActiv.minimum} max={allData.PersActiv.maximum} name="PersActiv" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_STADE_NBPERS" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData}/* ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2, true) }}*/ startLabel="text.nombre_de_matchs_journalier" type="select" value={data.NbMPJ} valuelist={allData.NbMPJ.enum} name="NbMPJ" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_STADE_NBMATCH" //NOSONAR
                    />
                    {/* eslint-disable-next-line eqeqeq */}
                    <ApplicationFormField id={id} allData={allData} ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2) }} onError={onError} startLabel="text.demarrage_d_un_match_toutes_les" endLabel="text.min" type="number" value={data.TpsActSport} min={allData.TpsActSport.minimum} max={allData.TpsActSport.maximum} name="TpsActSport" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_STADE_DEMARRAGE" disabled={data.NbMPJ == 1} //NOSONAR
                    />

                </>
            case SubAppCode.CASERNE:
                return <>
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.nombre_de_postes_de_douches" type="number" value={data.NbD} min={allData.NbD.minimum} max={allData.NbD.maximum} name="NbD" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.nombre_de_personnes_par_session_de_douches" type="number" value={data.personnes} min={allData.personnes.minimum} max={allData.personnes.maximum} name="personnes" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_CASERN_NBPERS" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} /*ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2, true) }}*/ startLabel="text.nombre_de_sessions_de_douches_par_jour" type="select" valuelist={allData.NbPuisages.enum} value={data.NbPuisages} name="NbPuisages" setData={setData} formFieldClass="middle" />
                    {/* eslint-disable-next-line eqeqeq */}
                    <ApplicationFormField id={id} allData={allData} ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2) }} onError={onError} startLabel="text.une_session_de_douches_toutes_les" endLabel="text.min" type="number" value={data.TpsActSport} min={allData.TpsActSport.minimum} max={allData.TpsActSport.maximum} name="TpsActSport" setData={setData} formFieldClass="middle" disabled={data.NbPuisages == 1} //NOSONAR
                    />
                </>
            case SubAppCode.VESTIAIREDENTREPRISE:
                return <>
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.nombre_de_postes_de_douches" type="number" value={data.NbD} min={allData.NbD.minimum} max={allData.NbD.maximum} name="NbD" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.nombre_de_personnes_par_session_de_douches" type="number" value={data.personnes} min={allData.personnes.minimum} max={allData.personnes.maximum} name="personnes" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_OTHER_NBPERS" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} /*ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2, true) }}*/ startLabel="text.nombre_de_sessions_de_douches_par_jour" type="select" valuelist={allData.NbPuisages.enum} value={data.NbPuisages} name="NbPuisages" setData={setData} formFieldClass="middle" />
                    {/* eslint-disable-next-line eqeqeq */}
                    <ApplicationFormField id={id} allData={allData} ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2) }} onError={onError} startLabel="text.une_session_de_douches_toutes_les" endLabel="text.min" type="number" value={data.TpsActSport} min={allData.TpsActSport.minimum} max={allData.TpsActSport.maximum} name="TpsActSport" setData={setData} formFieldClass="middle" disabled={data.NbPuisages == 1} //NOSONAR
                    />
                </>
            case SubAppCode.STATIONDELAVAGE:
                return <>

                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.nombre_de_pistes" type="number" value={data.Piste} min={allData.Piste.minimum} max={allData.Piste.maximum} name="Piste" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.debit_nominal_lavage_brosse" endLabel="text.l_min" type="number" value={data.QBrosse} min={allData.QBrosse.minimum} max={allData.QBrosse.maximum} name="QBrosse" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2) }} onError={onError} startLabel="text.debit_nominal_lavage_haute_pression" endLabel="text.l_min" type="number" value={data.QHp} min={allData.QHp.minimum} max={allData.QHp.maximum} name="QHp" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[3]} onKeyDown={(e) => { onKeyDown(e, 3) }} onError={onError} startLabel="text.temperature_de_lavage" endLabel="text.celsius_degree" type="select" valuelist={allData.TEC.enum} value={data.TEC} name="TEC" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                </>
            case SubAppCode.INTERNAT:
                return <>
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.nombre_de_postes_de_douches" type="number" value={data.NbD} min={allData.NbD.minimum} max={allData.NbD.maximum} name="NbD" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.nombre_de_lits" type="number" value={data.NbLit} min={allData.NbLit.minimum} max={allData.NbLit.maximum} name="NbLit" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                </>
            case SubAppCode.GYMNASE:
                return <>

                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.nombre_de_postes_de_douches" type="number" value={data.NbD} min={allData.NbD.minimum} max={allData.NbD.maximum} name="NbD" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.nombre_de_personnes_en_simultane" type="number" value={data.personnes} name="personnes" min={allData.personnes.minimum} max={allData.personnes.maximum} setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} /*ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2, true) }}*/ startLabel="text.nombre_d_activites_sportives_journalieres" type="select" valuelist={allData.NbActiv.enum} value={data.NbActiv} name="NbActiv" setData={setData} formFieldClass="middle" />
                    {/* eslint-disable-next-line eqeqeq */}
                    <ApplicationFormField id={id} allData={allData} ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2) }} onError={onError} startLabel="text.demarrage_d_une_activite_sportive_toutes_les" endLabel="text.min" type="number" value={data.TpsActSport} name="TpsActSport" min={allData.TpsActSport.minimum} max={allData.TpsActSport.maximum} setData={setData} formFieldClass="middle" disabled={data.NbActiv == 1} //NOSONAR
                    />
                </>
            case SubAppCode.PISCINE:
            case SubAppCode.SALLEDEFITNESS:
                return <>
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.frequentation_journaliere" endLabel="text.pers_j" type="number" value={data.freq} min={allData.freq.minimum} max={allData.freq.maximum} name="freq" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.nombre_de_postes_de_douches" type="number" value={data.NbD} min={allData.NbD.minimum} max={allData.NbD.maximum} name="NbD" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                </>
            case SubAppCode.HOPITALCLINIQUEDOUCHES:
            case SubAppCode.EHPAD:
                return <>
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.nombre_de_lits" type="number" value={data.NbLE} min={allData.NbLE.minimum} max={allData.NbLE.maximum} name="NbLE" setData={setData} formFieldClass="middle" startLabelProps={{ className: "" }} //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.nombre_de_repas" type="number" value={data.NbRE} min={allData.NbRE.minimum} max={allData.NbRE.maximum} name="NbRE" setData={setData} formFieldClass="middle" startLabelProps={{ className: "" }} hasInfoButton={true} infoValue="text.FO_HELP_HOSPITAL_EHPAD_NBREPAS" //NOSONAR
                    />
                </>
            case SubAppCode.TRADITIONNEL:
            case SubAppCode.GASTRONOMIQUE:
            case SubAppCode.RAPIDE:
            case SubAppCode.COLLECTIF:
                return <>
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.nombre_de_repas_le_midi" type="number" value={data.NbRepasMidi} min={allData.NbRepasMidi.minimum} max={allData.NbRepasMidi.maximum} name="NbRepasMidi" setData={setData} formFieldClass="middle" startLabelProps={{ className: "" }} //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.nombre_de_repas_le_soir" type="number" value={data.NbRepasSoir} min={allData.NbRepasSoir.minimum} max={allData.NbRepasSoir.maximum} name="NbRepasSoir" setData={setData} formFieldClass="middle" startLabelProps={{ className: "" }} //NOSONAR
                    />
                </>
            case SubAppCode.PROCESSUSINDUSTRIEL:
                return <>
                    <ApplicationFormField id={id} allData={allData} ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0) }} onError={onError} startLabel="text.tempErature_de_puisage" endLabel="text.celsius_degree" type="select" valuelist={allData.TEC.enum} value={data.TEC} name="TEC" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_INDUSTRY_TEMP_PUIS" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1) }} onError={onError} startLabel="text.volume_deau_chaude_par_cycle" endLabel="L" type="number" value={data.BEC} min={allData.BEC.minimum} max={allData.BEC.maximum} name="BEC" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2) }} onError={onError} startLabel="text.duree_dun_cycle" endLabel="text.min" type="number" value={data.Pointe} min={allData.Pointe.minimum} max={allData.Pointe.maximum} name="Pointe" setData={setData} formFieldClass="middle" hasInfoButton={true} infoValue="text.FO_HELP_INDUSTRY_CYCLE" //NOSONAR
                    />
                    <ApplicationFormField id={id} allData={allData} ref={refs[3]} onKeyDown={(e) => { onKeyDown(e, 3) }} onError={onError} startLabel="text.nombre_de_cycles_de_puisage_par_jour" type="select" valuelist={allData.NbPuisages.enum} value={data.NbPuisages} name="NbPuisages" setData={setData} formFieldClass="middle" //NOSONAR
                    />
                    {/* eslint-disable-next-line eqeqeq */}
                    <ApplicationFormField id={id} allData={allData} ref={refs[4]} onKeyDown={(e) => { onKeyDown(e, 4) }} onError={onError} startLabel="text.temps_de_pause_entre_2_cycles" endLabel="text.min" type="number" value={data.TpsRetCons} min={allData.TpsRetCons.minimum} max={allData.TpsRetCons.maximum} name="TpsRetCons" setData={setData} formFieldClass="middle" disabled={data.NbPuisages == 1} //NOSONAR
                    />

                </>
            default:
                return null;
        }
    } else {
        return null;
    }
}

const CalculatedFields = (props) => {
    const { calculatedApiData, expertModeChanged, application, sectionHeaderStyle } = props;

    return (<>
        <div className="sections">
            <div className="form_fields small">
                <div className="input_cont title">
                    <text className="" style={sectionHeaderStyle}><b><T>text.estimation_des_besoins_ecs</T></b></text>
                    <DataTip title={"text.FO_HELP_ESTI"}>
                        <div className="button micro info"><icon>info</icon></div>
                    </DataTip>
                </div>
            </div>
            {application !== AppCode.RESTAURANT ? <ApplicationFormField  startLabel="text.volume_pointe_10_min" endLabel="text.liters_a_60_c" type="number" value={calculatedApiData.V10} name="V10" disabled={true} formFieldClass="x_middle  read_only" startLabelProps={{ className: "", wrap: "", data_length: "xxxx-large" }} /> : null}
            <ApplicationFormField startLabel="text.duree_pointe_pluri_horaire" endLabel="text.FO_ESTI_h" type="textInput" value={calculatedApiData.tph ? formatMinutes(calculatedApiData.tph) : ""} name="tph" disabled={true} formFieldClass="x_middle  read_only" startLabelProps={{ className: "", wrap: "", data_length: "xxxx-large" }} readOnly />
            <ApplicationFormField startLabel="text.volume_pointe_pluri_horaire" endLabel="text.liters_a_60_c" type="number" value={calculatedApiData.Vph} name="Vph" disabled={true} formFieldClass="x_middle  read_only" startLabelProps={{ className: "", wrap: "", data_length: "xxxx-large" }} />
            <ApplicationFormField startLabel="text.volume_journalier" endLabel="text.liters_a_60_c" type="number" value={calculatedApiData.Vj} name="Vj" disabled={true} formFieldClass="x_middle  read_only" startLabelProps={{ className: "", wrap: "", data_length: "xxxx-large" }} />

            {expertModeChanged && <ApplicationFormField value={i18n("text.the_expert_input_data_has_been_modified")} type="text" disabled={true} formFieldClass="middle read_only" />}
        </div>
    </>)
}

const ExpertModeContent = (props) => {

   // const { params: { id } } = useRouteMatch(Project.getPageUrl(AppPages.Applications, [':application?', ':subapplication?', ':id?', ":zone?"]));
    let { data, setData, subapplication, allData, setExpertModeType, expertModeType, onError, setEtudeAllData, id } = props;
    const [isChecked, setIsChecked] = useState(expertModeType === "batiment");
    const [refs, setRefs] = useState({});

    const radioChange = useCallback((e) => {
        setExpertModeType(e.target.value);
        setEtudeAllData(item => ({ ...item, expertModeType: e.target.value }));
        setIsChecked(!isChecked);
    }, [isChecked]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRefs((prev) => Array(getFieldsCount(subapplication).expertModeFieldsCount)
            .fill()
            .map((_, i) => refs[i] || createRef()),
        );
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const onKeyDown = (e, index) => { //NOSONAR
        let code = e.event.code;
        if (code === 'ArrowDown' || code === 'ArrowUp' || code === 'Enter' || code === 'NumpadEnter') {
            e.event.preventDefault();
            e.event.stopPropagation();
        }
        if (code === 'ArrowDown' && refs[index + 1]) {
            refs[index + 1].current.props.prefixCls ? refs[index + 1].current.focus() : refs[index + 1].current.instance.focus();
        } else if (code === 'ArrowUp' && refs[index - 1]) {
            refs[index - 1].current.props.prefixCls ? refs[index - 1].current.focus() : refs[index - 1].current.instance.focus();
        } else if (code === 'Enter' || code === 'NumpadEnter') {
            refs[index].current.props.prefixCls ? refs[index].current.focus() : refs[index].current.instance.blur();
        }
    }


    if (data) {
        
        switch (subapplication) {
            case SubAppCode.LOGEMENTSPRIVES:
            case SubAppCode.LOGEMENTSSOCIAUX:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <label radio=""><Input name="maximaleParLit" onChange={radioChange} value="appartement" checked={!expertModeType || expertModeType === "appartement"}  type="radio" /><box><check></check></box><text > <T>text.par_type_dappartement</T></text></label>
                        </div>
                    </div>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }} id={id} onError={onError} allData={allData} startLabel="text.t1" endLabel="text.liters_a_60_c" type="number" value={data.consoT1} min={allData.consoT1.minimum} max={allData.consoT1.maximum} name="consoT1" setData={setData} disabled={isChecked} formFieldClass="middle" endLabelProps={{ className: "description middle", wrap: "" }} startLabelProps={{ className: " ", wrap: "", data_length: "xxx-small" }} />
                            <ApplicationFormField ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1, true) }} id={id} onError={onError} allData={allData} startLabel="text.t2" endLabel="text.liters_a_60_c" type="number" value={data.consoT2} min={allData.consoT2.minimum} max={allData.consoT2.maximum} name="consoT2" setData={setData} disabled={isChecked} formFieldClass="middle" endLabelProps={{ className: "description middle", wrap: "" }} startLabelProps={{ className: " ", wrap: "", data_length: "xxx-small" }} />
                            <ApplicationFormField ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2, true) }} id={id} onError={onError} allData={allData} startLabel="text.t3" endLabel="text.liters_a_60_c" type="number" value={data.consoT3} min={allData.consoT3.minimum} max={allData.consoT3.maximum} name="consoT3" setData={setData} disabled={isChecked} formFieldClass="middle" endLabelProps={{ className: "description middle", wrap: "" }} startLabelProps={{ className: " ", wrap: "", data_length: "xxx-small" }} />
                            <ApplicationFormField ref={refs[3]} onKeyDown={(e) => { onKeyDown(e, 3, true) }} id={id} onError={onError} allData={allData} startLabel="text.t4" endLabel="text.liters_a_60_c" type="number" value={data.consoT4} min={allData.consoT4.minimum} max={allData.consoT4.maximum} name="consoT4" setData={setData} disabled={isChecked} formFieldClass="middle" endLabelProps={{ className: "description middle", wrap: "" }} startLabelProps={{ className: " ", wrap: "", data_length: "xxx-small" }} />
                            <ApplicationFormField ref={refs[4]} onKeyDown={(e) => { onKeyDown(e, 4, true) }} id={id} onError={onError} allData={allData} startLabel="text.t5" endLabel="text.liters_a_60_c" type="number" value={data.consoT5} min={allData.consoT5.minimum} max={allData.consoT5.maximum} name="consoT5" setData={setData} disabled={isChecked} formFieldClass="middle" endLabelProps={{ className: "description middle", wrap: "" }} startLabelProps={{ className: " ", wrap: "", data_length: "xxx-small" }} />
                            <ApplicationFormField ref={refs[5]} onKeyDown={(e) => { onKeyDown(e, 5, true) }} id={id} onError={onError} allData={allData} startLabel="text.t6" endLabel="text.liters_a_60_c" type="number" value={data.consoT6} min={allData.consoT6.minimum} max={allData.consoT6.maximum} name="consoT6" setData={setData} disabled={isChecked} formFieldClass="middle" endLabelProps={{ className: "description middle", wrap: "" }} startLabelProps={{ className: " ", wrap: "", data_length: "xxx-small" }} />
                        </div>
                    </div>
                    <div className="section_group">
                        <div className="sections auto">
                            <label radio=""><Input name="maximaleParLit" onChange={radioChange} value="batiment" checked={expertModeType === "batiment"} type="radio" /><box><check></check></box><text><T>text.pour_le_batiment</T></text></label>
                        </div>
                    </div>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField id={id} onError={onError} allData={allData} startLabel="" endLabel="text.liters_a_60_c" type="number" value={data.consoBatiment} min={allData.consoBatiment.minimum} max={allData.consoBatiment.maximum} name="consoBatiment" setData={setData} disabled={!isChecked} formFieldClass="middle" endLabelProps={{ className: "description middle", wrap: "" }} startLabelProps={{ className: " ", wrap: "", data_length: "ss-small" }} />
                        </div>
                    </div>
                </>)
            case SubAppCode.RESIDENCESSENIORS:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <label radio=""><Input name="consommationJournaliereMaximaleParLit" onChange={radioChange} value="logement" checked={expertModeType === null || expertModeType === "logement"} type="radio" /><box><check></check></box><text><T>text.par_logement</T></text></label>
                            {/*<ApplicationFormField allData={allData} endLabel="text.liters_a_60_c" type="number" value={data.consoT1} min={allData.consoT1.minimum} max={allData.consoT1.maximum} name="consoT1" setData={setData} disabled={type} formFieldClass="middle" endLabelProps={{ className: "description middle", wrap: "" }} />*/}
                            <ApplicationFormField ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }} id={id} allData={allData} onError={onError} endLabel="text.liters_a_60_c" type="number" value={data.consoT1} min={allData.consoT1.minimum} max={allData.consoT1.maximum} name="consoT1" setData={setData} disabled={isChecked} formFieldClass="middle" endLabelProps={{ className: "description middle", wrap: "" }} />
                        </div>
                    </div>
                    <div className="section_group">
                        <div className="sections auto">
                            <div className="form_fields">
                                <text >
                                    <T>text.ou</T>
                                </text>
                            </div>
                            <label radio=""><Input name="consommationJournaliereMaximaleParLit" onChange={radioChange} value="batiment" checked={expertModeType === "batiment"} type="radio" /><box><check></check></box><text><T>text.pour_le_batiment</T></text></label>
                            <ApplicationFormField ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }} id={id} allData={allData} onError={onError} endLabel="text.liters_a_60_c" type="number" value={data.consoBatiment} min={allData.consoBatiment.minimum} max={allData.consoBatiment.maximum} name="consoBatiment" setData={setData} disabled={!isChecked} formFieldClass="middle" endLabelProps={{ className: "description middle", wrap: "" }} />
                        </div>
                    </div>
                </>)
            case SubAppCode.RESIDENCESETUDIANTE:
                return (<>
                    <div className="section_group">
                        <div className="sections">
                            <ApplicationFormField  id={id} allData={allData} startLabel="text.volume_journalier_maximal_consomme_par_lit_a_60_c" endLabel="text.liters_lit_60_c" type="select" valuelist={allData.Cjpl.enum} value={data.Cjpl} name="Cjpl" setData={setData} formFieldClass="middle" endLabelProps={{ className: "", wrap: "" }} />
                        </div>
                    </div>
                    <div className="section_group">
                        <div className="sections">
                            <div className="form_fields"><div className="input_cont"><text underline=""><T>text.repas</T></text></div></div>
                            <ApplicationFormField id={id} allData={allData} startLabel="text.consommation_ecs_par_couvert" endLabel="text.liters_a_60_c" type="select" valuelist={allData.ECSbyCouv.enum} value={data.ECSbyCouv} name="ECSbyCouv" setData={setData} formFieldClass="middle" startLabelProps={{ className: "", wrap: "" }} endLabelProps={{ className: "", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_pointe_de_restauration" endLabel="text.min" type="select" valuelist={allData.tRestau.enum} value={data.tRestau} name="tRestau" setData={setData} formFieldClass="middle" startLabelProps={{ className: "", wrap: "" }} endLabelProps={{ className: "", wrap: "" }} />
                            <FieldGroup>
                                <ApplicationFormField id={id} allData={allData} startLabel="text.simultaneite_du_besoin_ecs_restauration_avec_le_besoin_des_chambres" type="select" valuelist={allData.Simult.enum} value={data.Simult} translate={true} name="Simult" setData={setData} formFieldClass="x_middle" startLabelProps={{ className: "", wrap: "" }} hasInfoButton={true} infoValue="text.FO_HELP_SIMUL" />
                            </FieldGroup>
                        </div>
                    </div>
                </>)
            case SubAppCode.FOYERDEJEUNESTRAVAILLEURS:
                return (<>
                    <div className="section_group">
                        <div className="sections">
                            <ApplicationFormField  id={id} allData={allData} endLabel="text.liters_a_60_c" type="select" valuelist={allData.Cjpl.enum} value={data.Cjpl} name="Cjpl" setData={setData} formFieldClass="middle" endLabelProps={{ className: "", wrap: "" }} />
                        </div>
                    </div>
                    <div className="section_group">
                        <div className="sections">
                            <div className="form_fields"><div className="input_cont"><text underline=""><T>text.repas</T></text></div></div>
                            <ApplicationFormField id={id} allData={allData} startLabel="text.consommation_ecs_par_couvert" endLabel="text.liters_a_60_c" type="select" valuelist={allData.ECSbyCouv.enum} value={data.ECSbyCouv} name="ECSbyCouv" setData={setData} formFieldClass="middle" startLabelProps={{ className: "", wrap: "" }} endLabelProps={{ className: "", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_pointe_de_restauration" endLabel="text.min" type="select" valuelist={allData.tRestau.enum} value={data.tRestau} name="tRestau" setData={setData} formFieldClass="middle" startLabelProps={{ className: "", wrap: "" }} endLabelProps={{ className: "", wrap: "" }} />
                            <FieldGroup>
                                <ApplicationFormField id={id} allData={allData} startLabel="text.simultaneite_du_besoin_ecs_restauration_avec_le_besoin_des_chambres" type="select" valuelist={allData.Simult.enum} value={data.Simult} translate={true} name="Simult" setData={setData} formFieldClass="x_middle" startLabelProps={{ className: "", wrap: "" }} hasInfoButton={true} infoValue="text.FO_HELP_SIMUL" />
                            </FieldGroup>
                        </div>
                    </div>
                </>)
            case SubAppCode.CAMPING:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField id={id}  allData={allData} startLabel="text.duree_daffluence_au_bloc_sanitaire" type="select" valuelist={allData.PfB.enum} value={data.PfB} name="PfB" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} />

                        </div>
                    </div>
                    <div className="section_group">
                        <div className="sections auto">
                            <div className="form_fields middle">
                                <div className="input_cont">
                                    <text className="description" wrap="" style={{ textDecorationLine: "underline" }}><T>text.equipements_du_bloc_sanitaire</T></text>
                                    <DataTip title={"text.FO_HELP_EQUIP"}>
                                        <div className="button micro info"><icon>info</icon></div>
                                    </DataTip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }} id={id} allData={allData} onError={onError} startLabel="text.douches" type="number" value={data.NbD} min={allData.NbD.minimum} max={allData.NbD.maximum} name="NbD" setData={setData} formFieldClass="middle" disabled={true} />
                            <ApplicationFormField ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1, true) }} id={id} allData={allData} onError={onError} startLabel="text.lavabos" type="number" value={data.NbL} min={allData.NbL.minimum} max={allData.NbL.maximum} name="NbL" setData={setData} formFieldClass="middle" />
                            <ApplicationFormField ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2, true) }} id={id} allData={allData} onError={onError} startLabel="text.bacs_a_laver_la_vaisselle" type="number" value={data.NbBV} min={allData.NbBV.minimum} max={allData.NbBV.maximum} name="NbBV" setData={setData} formFieldClass="middle" />
                            <ApplicationFormField ref={refs[3]} onKeyDown={(e) => { onKeyDown(e, 3, true) }} id={id} allData={allData} onError={onError} startLabel="text.bacs_a_laver_la_linge" type="number" value={data.NbBL} min={allData.NbBL.minimum} max={allData.NbBL.maximum} name="NbBL" setData={setData} formFieldClass="middle" />
                        </div>
                    </div>
                </>)
            case SubAppCode.AFFAIRES:
            case SubAppCode.MONTAGNE:
            case SubAppCode.TOURISME:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField id={id} allData={allData} startLabel="text.consommation_journaliere_par_chambre" endLabel="text.liters_a_60_c" type="select" valuelist={allData.Cjpc.enum} value={data.Cjpc} name="Cjpc" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <div className="form_fields">
                                <text style={{ textDecorationLine: "underline" }}>
                                    <T>text.repas</T>
                                </text>
                            </div>
                            <ApplicationFormField id={id} allData={allData} startLabel="text.consommation_ecs_par_couvert" endLabel="text.liters_a_60_c_couvert" type="select" valuelist={allData.ECSbyCouv.enum} value={data.ECSbyCouv} name="ECSbyCouv" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_pointe_de_restauration" endLabel="text.min" type="select" valuelist={allData.tRestau.enum} value={data.tRestau} name="tRestau" setData={setData} formFieldClass="middle" startLabelProps={{ className: " ", wrap: "", data_length: "xxxx-large" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <FieldGroup>
                                <ApplicationFormField id={id} allData={allData} startLabel="text.simultaneite_du_besoin_ecs_restauration_avec_le_besoin_des_chambres" type="select" valuelist={allData.Simult.enum} value={data.Simult} translate={true} name="Simult" setData={setData} formFieldClass="x_middle" startLabelProps={{ className: "", wrap: "", data_length: "xxxx-large" }} hasInfoButton={true} infoValue="text.FO_HELP_SIMUL"/>
                            </FieldGroup>
                        </div>
                    </div>
                </>)
            case SubAppCode.STADEFOOTBALL:
            case SubAppCode.STADERUGBY:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField id={id} allData={allData} startLabel="text.debit_nominal_d_une_douche" endLabel="text.l_min" type="select" valuelist={allData.QDch.enum} value={data.QDch} name="QDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} onError={onError} startLabel="text.duree_d_ecoulement_d_une_douche" endLabel="text.min" type="select" valuelist={allData.TpsEcDch.enum} value={data.TpsEcDch} name="TpsEcDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} onError={onError} startLabel="text.duree_d_occupation_d_une_douche_par_personne" endLabel="text.min" type="select" valuelist={allData.TpsTotDch.enum} value={data.TpsTotDch} name="TpsTotDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                        </div>
                    </div>
                </>)
            case SubAppCode.CASERNE: /*TODO: CASERNE SAME AS STADEFOOTBALL STADERUGBY*/ /*NOSONAR*/
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField id={id} allData={allData} startLabel="text.debit_nominal_d_une_douche" endLabel="text.l_min" type="select" valuelist={allData.QDch.enum} value={data.QDch} name="QDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_d_ecoulement_d_une_douche" endLabel="text.min" type="select" valuelist={allData.TpsEcDch.enum} value={data.TpsEcDch} name="TpsEcDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_d_occupation_d_une_douche_par_personne" endLabel="text.min" type="select" valuelist={allData.TpsTotDch.enum} value={data.TpsTotDch} name="TpsTotDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }} id={id} allData={allData} onError={onError} startLabel="text.duree_de_pointe" endLabel="text.min" type="number" value={data.PointeModif} min={allData.PointeModif.minimum} max={allData.PointeModif.maximum} name="PointeModif" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                        </div>
                    </div>
                </>)
            case SubAppCode.VESTIAIREDENTREPRISE:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField id={id} allData={allData} startLabel="text.debit_nominal_d_une_douche" endLabel="text.l_min" type="select" valuelist={allData.QDch.enum} value={data.QDch} name="QDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_d_ecoulement_d_une_douche" endLabel="text.min" type="select" valuelist={allData.TpsEcDch.enum} value={data.TpsEcDch} name="TpsEcDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} onError={onError} startLabel="text.duree_d_occupation_d_une_douche_par_personne" endLabel="text.min" type="select" valuelist={allData.TpsTotDch.enum} value={data.TpsTotDch} name="TpsTotDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                        </div>
                    </div>
                </>)
            case SubAppCode.STATIONDELAVAGE:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <div className="form_fields">
                                <text style={{ textDecorationLine: "underline" }}>
                                    <T>text.cycle_complet</T>
                                </text>
                            </div>
                            <ApplicationFormField ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }} id={id} allData={allData} onError={onError} startLabel="text.duree_lavage_brosse__eau_chaude" endLabel="text.min_s" type="number" value={data.TpsBrosse} min={allData.TpsBrosse.minimum} max={allData.TpsBrosse.maximum} name="TpsBrosse" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1, true) }} id={id} allData={allData} onError={onError} startLabel="text.duree_lavage_hp__eau_chaude" endLabel="text.min_s" type="number" value={data.TpsHp} min={allData.TpsHp.minimum} max={allData.TpsHp.maximum} name="TpsHp" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2, true) }} id={id} allData={allData} onError={onError} startLabel="text.duree_rincage_finition_eau_froide" endLabel="text.min_s" type="number" value={data.TpsRinc} min={allData.TpsRinc.minimum} max={allData.TpsRinc.maximum} name="TpsRinc" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField ref={refs[3]} onKeyDown={(e) => { onKeyDown(e, 3, true) }} id={id} allData={allData} onError={onError} startLabel="text.duree_sans_puisage_entre_2_vehicules" endLabel="text.min_s" type="number" value={data.TpsSSPuis} min={allData.TpsSSPuis.minimum} max={allData.TpsSSPuis.maximum} name="TpsSSPuis" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_de_la_periode_daffluence" endLabel="text.h" type="select" valuelist={allData.NbPuisages.enum} value={data.NbPuisages} name="NbPuisages" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} onError={onError} startLabel="text.nombre_de_vehicules_par_periode_daffluence" value={data.period} type="number" disabled={true} formFieldClass="middle" /*startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }}*/ />

                        </div>
                    </div>
                </>)
            case SubAppCode.INTERNAT:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField id={id} allData={allData} startLabel="text.debit_nominal_d_une_douche" endLabel="text.l_min" type="select" valuelist={allData.QDch.enum} value={data.QDch} name="QDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_d_ecoulement_d_une_douche" endLabel="text.min" type="select" valuelist={allData.TpsEcDch.enum} value={data.TpsEcDch} name="TpsEcDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} onError={onError} startLabel="text.duree_d_occupation_d_une_douche_par_personne" endLabel="text.min" type="select" valuelist={allData.TpsTotDch.enum} value={data.TpsTotDch} name="TpsTotDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }} id={id} allData={allData} onError={onError} startLabel="text.consommation_journaliere_par_lit" endLabel="text.la_60_C" type="number" value={data.ConsoJourLit} min={allData.ConsoJourLit.minimum} max={allData.ConsoJourLit.maximum} name="ConsoJourLit" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />


                        </div>
                    </div>
                </>)
            case SubAppCode.GYMNASE:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField id={id} allData={allData} startLabel="text.debit_nominal_d_une_douche" endLabel="text.l_min" type="select" valuelist={allData.QDch.enum} value={data.QDch} name="QDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_d_ecoulement_d_une_douche" endLabel="text.min" type="select" valuelist={allData.TpsEcDch.enum} value={data.TpsEcDch} name="TpsEcDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_d_occupation_d_une_douche_par_personne" endLabel="text.min" type="select" valuelist={allData.TpsTotDch.enum} value={data.TpsTotDch} name="TpsTotDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            {/*<ApplicationFormField allData={allData} startLabel="text.duree_d_une_session_de_douches" endLabel="text.min" type="number" value={data.TpsSesDch} name="TpsSesDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />*/}
                        </div>
                    </div>
                </>)
            case SubAppCode.PISCINE:
            case SubAppCode.SALLEDEFITNESS:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField id={id} allData={allData} startLabel="text.periode_d_ouverture_d_acces_aux_bassins" endLabel="text.h" type="select" valuelist={allData.NbPuisages.enum} value={data.NbPuisages} name="NbPuisages" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }} id={id} allData={allData} onError={onError} startLabel="text.periode_d_affluence" endLabel="text.h" type="number" value={data.TpsAffl} name="TpsAffl" min={allData.TpsAffl.minimum} max={allData.TpsAffl.maximum} setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <div className="form_fields">
                                <text style={{ textDecorationLine: "underline" }}>
                                    <T>text.fréquentation_horaire</T>
                                </text>
                            </div>
                            <ApplicationFormField ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1, true) }} id={id} allData={allData} onError={onError} startLabel="text.periode_d_affluence" endLabel="text.pers_h" type="number" value={data.Affl} min={allData.Affl.minimum} max={allData.Affl.maximum} name="Affl" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} hasInfoButton={true} infoValue="text.FO_HELP_SWIM_FITNESS_PA" />
                            <ApplicationFormField ref={refs[2]} onKeyDown={(e) => { onKeyDown(e, 2, true) }} id={id} allData={allData} onError={onError} startLabel="text.periode_hors_affluence" endLabel="text.pers_h" type="number" value={data.NAffl} name="NAffl" min={allData.NAffl.minimum} max={allData.NAffl.maximum} setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} disabled={true} hasInfoButton={true} infoValue="text.FO_HELP_SWIM_FITNESS_PHA" />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.debit_nominal_d_une_douche" endLabel="text.l_min" type="select" valuelist={allData.QDch.enum} value={data.QDch} name="QDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_d_ecoulement_d_une_douche" endLabel="text.min" type="select" valuelist={allData.TpsEcDch.enum} value={data.TpsEcDch} name="TpsEcDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_d_occupation_d_une_douche_par_personne" endLabel="text.min" type="select" valuelist={allData.TpsTotDch.enum} value={data.TpsTotDch} name="TpsTotDch" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description", wrap: "" }} />
                        </div>
                    </div>
                </>)
            case SubAppCode.HOPITALCLINIQUEDOUCHES:
            case SubAppCode.EHPAD:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField ref={refs[0]} onKeyDown={(e) => { onKeyDown(e, 0, true) }} id={id} allData={allData} onError={onError} endLabel="text.liters_a_60_c" startLabel="text.volume_journalier_maximal_consomme_par_lit_a_60_c" type="number" value={data.VJRef} min={allData.VJRef.minimum} max={allData.VJRef.maximum} name="VJRef" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} />
                            <ApplicationFormField ref={refs[1]} onKeyDown={(e) => { onKeyDown(e, 1, true) }} id={id} allData={allData} endLabel="text.liters_a_60_c" startLabel="text.volume_ecs_par_repas_hors_lita_titre_dinformation" type="number" value={4} disabled={true} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} />
                        </div>
                    </div>
                </>)
            case SubAppCode.TRADITIONNEL:
            case SubAppCode.GASTRONOMIQUE:
            case SubAppCode.RAPIDE:
            case SubAppCode.COLLECTIF:
                return (<>
                    <div className="section_group">
                        <div className="sections auto">
                            <ApplicationFormField id={id} allData={allData} startLabel="text.consommation_decs_par_repas" endLabel="text.l_a_60_c_repas" type="select" valuelist={allData.ECSbyRepas.enum} value={data.ECSbyRepas} name="ECSbyRepas" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description  x.middle", wrap: "" }} />
                            <ApplicationFormField id={id} allData={allData} startLabel="text.duree_de_pointe_de_consommation_ecs" endLabel="text.min" type="select" valuelist={allData.tRestau.enum} value={data.tRestau} name="tRestau" setData={setData} formFieldClass="middle" startLabelProps={{ className: "description", wrap: "" }} endLabelProps={{ className: "description middle", wrap: "" }} />
                        </div>
                    </div>
                </>)
            default:
                return null;
        }
    } else {
        return null;
    }

}